import React from 'react';
import { when, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import LuckyWheelOrderConfScreen from './LuckyWheelOrderConfScreen';
import PopupResetKiosk from './PopupResetKiosk';
import { RESOURCE, MAYBE } from '../../../services/types';
import { CircleLoader } from '../../core/Loading';
import Modal from '../../core/Modal';

import PopupPhoneNumber from './PopupPhoneNumber';
// import PopupTableNumber from './PopupTableNumber';
import PopupOrdering from './PopupOrdering';
import PopupConfirm from './PopupConfirm';
import CartSummary from './CartSummary';
import SpacePadder from './SpacePadder';
import { services } from '../../../services/index';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { onDocumentIdle } from '../../../services/domUtils';
import { GetQueryParamFromUrl } from '../../../services/url';
import PopupAddonItems from './PopupAddonItems';
import MenuItemsUI from './MenuItemsUI';
import PopupPaymentOption from './PopupPaymentOption';
import PopupSnackDessertItems from './PopupSnackDessertItems';
import PopupQrCode from './PopupQrCode';
import PopupPaymentConfirm from './PopupPaymentConfirm';

import PopupRewardCode from './PopupRewardCode';
import PopupTapCard from './PopupTapCard';
import PopupPaymentFailure from './PopupPaymentFailure';
import CardReaderStatus from './CardReaderStatus';
import { version } from '../../../../package.json';
import Cookies from 'js-cookie';
const base = 'terminal';

@inject('terminalMenuStore', 'terminalCartStore')
@observer
export default class extends React.Component {
    targetElement = null;
    state = {
        isIpad: false,
        focus: false,
        headerHeight: 0,
    };

    componentDidMount() {
        const { terminalMenuStore, terminalCartStore } = this.props;
        const ua = navigator.userAgent;
        const isIpad = /iPad/i.test(ua) || /iPhone OS 3_1_2/i.test(ua) || /iPhone OS 3_2_2/i.test(ua);

        this.setState({ isIpad: isIpad });

        const bearerToken = GetQueryParamFromUrl('bearerToken');
        if (bearerToken) {
            terminalMenuStore.bearerTokenActions(bearerToken);
        }
        
        when(() => terminalMenuStore.menuDate !== '', ::terminalMenuStore.removeOldItems);
        when(
            () => MAYBE.Some.is(terminalMenuStore.orderInfo)
            // ::terminalMenuStore.emptyCart
        );
        when(() => RESOURCE.Success.is(terminalMenuStore.itemsFetcher), this.handleShowHideSummary);
        reaction(() => terminalCartStore.numOfItems, this.handleShowHideSummary);

        services.api.UserCart.clear();

        // onDocumentIdle({
        //     seconds: 180,
        //     callback: this.handleRefreshMenu,
        //     checkEverySeconds: 30,
        // });

        if (isIpad) {
            when(
                () =>
                    terminalMenuStore.showingPopup === terminalMenuStore.POPUP_ADDON_ITEMS ||
                    terminalMenuStore.showingPopup === terminalMenuStore.POPUP_SNACK_DESSERT_ITEMS ||
                    terminalMenuStore.showingPopup === terminalMenuStore.POPUP_PHONE_NUMBER ||
                    terminalMenuStore.showingPopup === terminalMenuStore.POPUP_ORDERING ||
                    terminalMenuStore.showingPopup === terminalMenuStore.POPUP_PAYMENT_OPTION ||
                    terminalMenuStore.showingPopup === terminalMenuStore.POPUP_CONFIRM_ORDER,
                () => disableBodyScroll(document.querySelector('#modal-backdrop'))
            );
        }

        this.heartBeatInterval = setInterval(() => {
            this.sendHeartBeat();
        }, 1 * 60 * 1000);
        console.log("Adding card status listener");
        terminalMenuStore.cardReaderStatus = false;
        window.addEventListener("cardReaderStatusUpdate", terminalMenuStore.handleCardReaderStatusUpdate);
    }

    getTerminalUserName = () => localStorage.getItem('terminalUserName') || null;

    getDeviceId = () => this.props.terminalMenuStore.KIOSK_RANDOM_ID || null;

    sendHeartBeat = () => {
        let logString = `device=TERMINAL outlet=${this.getTerminalUserName()} version=${version} device_id=${this.getDeviceId()} hub_code=${Cookies.get('hubCode')}`;
        if (this.props.terminalMenuStore.getCardProvider() == 'stripe') {
            const status = localStorage.getItem('cardReaderStatus');
            logString += ` card_status=${status}`;
        }
        services.api.SendLogToBackend({
            log: logString,
        });
    };

    componentWillUnmount() {
        if (this.heartBeatInterval) {
            clearInterval(this.heartBeatInterval);
        }
        const { terminalMenuStore } = this.props;
        console.log("Removing card status listener");
        window.removeEventListener("cardReaderStatusUpdate", terminalMenuStore.handleCardReaderStatusUpdate);
    }

    handleRefreshMenu = () => {
        services.api.AccessTokenStorage.refreshToken();
        this.props.terminalMenuStore.refreshMenu();
    };

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.handleClickCta();
        }
    };

    handleShowHideSummary = () => {
        const { terminalMenuStore, terminalCartStore } = this.props;
        const isEmpty = terminalCartStore.cartServices.isEmptyAt({
            date: terminalMenuStore.menuDate,
        });

        if (!isEmpty) {
            terminalMenuStore.setShowSummary(true);
        }
    };

    handleClosePopup = () => {
        const { terminalMenuStore } = this.props;
        const { isIpad } = this.state;
        terminalMenuStore.couponValidation = null;
        if (terminalMenuStore.showingPopup == terminalMenuStore.POPUP_PHONE_NUMBER || 
            terminalMenuStore.showingPopup == terminalMenuStore.POPUP_PAYMENT_OPTION) {
            terminalMenuStore.removeNameInfo();
        }
        if (isIpad) {
            enableBodyScroll(document.querySelector('#modal-backdrop'));
        }

        if (terminalMenuStore.checkIn) {
            terminalMenuStore.checkIn = false;
        } else if (terminalMenuStore.showingPopup === terminalMenuStore.POPUP_SNACK_DESSERT_ITEMS) {
            terminalMenuStore.setShowPopup(terminalMenuStore.POPUP_PHONE_NUMBER);
        } else if (terminalMenuStore.showingPopup === terminalMenuStore.POPUP_CONFIRM_ORDER) {
            this.handleRefreshMenu();
            terminalMenuStore.setCouponTxt('');
            terminalMenuStore.setCouponDisable(false);
            terminalMenuStore.setCouponErrMessage(null);
            let orderType = GetQueryParamFromUrl('orderType');
            terminalMenuStore.showSplashScreenActions(orderType);
            terminalMenuStore.setOrderTypeIfExistsAndReloadMenu(orderType);
        } else {
            if (
                (terminalMenuStore.showingPopup != terminalMenuStore.POPUP_QRCODE && 
                    terminalMenuStore.showingPopup != terminalMenuStore.POPUP_TAP_CARD) 
                    && terminalMenuStore.orderNumber == ''
            ) {
                if (terminalMenuStore.showingPopup === 'reset-kiosk') {
                    terminalMenuStore.setShowPopup(terminalMenuStore.previousPopup);
                } else {
                    this.handleShowHideSummary();
                    terminalMenuStore.setShowPopup('');
                }
            } else if (terminalMenuStore.showingPopup == terminalMenuStore.POPUP_PAYMENT_OPTION && terminalMenuStore.orderNumber) {
                // Place new order
                terminalMenuStore.orderNumber = '';
                terminalMenuStore.backBtn = false;
                this.handleShowHideSummary();
                terminalMenuStore.setShowPopup('');
            } else if (terminalMenuStore.showingPopup === 'reset-kiosk' && terminalMenuStore.previousPopup) {
                terminalMenuStore.showingPopup = terminalMenuStore.previousPopup;
                terminalMenuStore.previousPopup = '';
            }
        }
    };

    handleFocus = () => {
        this.setState({ focus: true });
    };

    handleBlur = () => {
        this.setState({ focus: false });
    };

    handleClickToggle = (isTomorrow) => {
        const { terminalMenuStore } = this.props;
        terminalMenuStore.toggleMenuType(isTomorrow);
    };

    renderContent() {
        const store = this.props.terminalMenuStore;
        const { sectionsFetcher, itemsFetcher } = store;

        if (sectionsFetcher === RESOURCE.Loading) {
            return (
                <center>
                    <CircleLoader />
                </center>
            );
        }

        if (RESOURCE.Success.is(sectionsFetcher) && RESOURCE.Success.is(itemsFetcher)) {
            const items = itemsFetcher.data.filter((item) => item.quantity_left > 0);
            const sections = sectionsFetcher.data.filter((section) => section.id != null);

            if (sections && sections.length > 0 && items && items.length > 0) {
                return <MenuItemsUI items={items} sections={sections} />;
            } else {
                return <p>{intl.get('terminal.page.entry.noMeals')}</p>;
            }
        }

        return null;
    }

    handleClickCta = () => {
        const { terminalMenuStore } = this.props;
        terminalMenuStore.confirmOrder(false);
    };

    render() {
        const store = this.props.terminalMenuStore;
        const { drinkComboItems, dessertAndSnackItems } = store;
        const cardProvider = GetQueryParamFromUrl('cardProvider');
        if (cardProvider) {
            store.setCardProvider(cardProvider);
        }
        return (
            <div className={`${base}__top_container`}>
                <div>
                    <div style={{ paddingBottom: '28px' }} className={`${base}__body`}>
                        {this.renderContent()}
                    </div>
                    <SpacePadder />
                    <CartSummary />
                    <CardReaderStatus />
                    <Modal
                        showModal={Boolean(store.showingPopup)}
                        showCloseIcon={false}
                        contentClasses="modal__content--golden-ratio"
                        onBackdropClicked={this.handleClosePopup}
                        disableScrollingOnMobile={true}
                        contentClasses={
                            store.showingPopup === store.POPUP_CONFIRM_ORDER ? 'primary_color_gradient' : ''
                        }
                        manualStyles={{
                            ...((store.showingPopup === store.POPUP_PHONE_NUMBER ||
                                store.showingPopup === store.POPUP_TABLE_NUMBER) && {
                                top: '50%',
                                maxHeight: '100vh',
                            }),
                        }}
                    >
                        {store.showingPopup === store.POPUP_ADDON_ITEMS ? (
                            <PopupAddonItems items={drinkComboItems} onClosePopup={this.handleClosePopup} />
                        ) : null}
                        {store.showingPopup === 'reset-kiosk' ? (
                            <PopupResetKiosk onClosePopup={this.handleClosePopup} />
                        ) : null}
                        {store.showingPopup === store.POPUP_SNACK_DESSERT_ITEMS ? (
                            <PopupSnackDessertItems items={dessertAndSnackItems} />
                        ) : null}
                        {store.showingPopup === store.POPUP_PHONE_NUMBER ? (
                            <PopupPhoneNumber
                                onKeyPress={this.handleKeyPress}
                                onFocus={this.handleFocus}
                                onBlur={this.handleBlur}
                                onClosePopup={this.handleClosePopup}
                            />
                        ) : null}
                        {/* {store.showingPopup === store.POPUP_TABLE_NUMBER ? (
                            <PopupTableNumber
                                onKeyPress={this.handleKeyPress}
                                onFocus={this.handleFocus}
                                onBlur={this.handleBlur}
                            />
                        ) : null} */}
                        {store.showingPopup === store.POPUP_ORDERING ? <PopupOrdering checkIn={store.checkIn} /> : null}
                        {store.showingPopup === store.POPUP_CONFIRM_ORDER ? (
                            <PopupConfirm onClosePopup={this.handleClosePopup} />
                        ) : null}
                        {store.showingPopup === store.POPUP_PAYMENT_OPTION ? (
                            <PopupPaymentOption onClosePopup={this.handleClosePopup} />
                        ) : null}
                        {store.showingPopup === store.POPUP_QRCODE ? <PopupQrCode /> : null}
                        {store.showingPopup === store.POPUP_TAP_CARD ? <PopupTapCard /> : null}
                        {store.showingPopup === store.POPUP_PAYMENT_CONFIRM ? <PopupPaymentConfirm /> : null}
                        {store.showingPopup === store.POPUP_PAYMENT_FAILURE ? <PopupPaymentFailure /> : null}
                    </Modal>
                </div>
            </div>
        );
    }
}
