import React from 'react';

import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import QRCode from 'qrcode';

const base = 'terminal-payment-option';
@inject('terminalCartStore')
@inject('terminalMenuStore')
@observer
export default class extends React.Component {
    timeoutId = null;

    componentDidMount() {
        const { terminalMenuStore } = this.props;
        if (terminalMenuStore.paymentType == "Card") {
            this.setPopupTimeout(10000);
        } else {
            this.setPopupTimeout(5000);
        }
    }

    setPopupTimeout(duration) {
        const { terminalMenuStore } = this.props;

        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        
        if (terminalMenuStore.showQR) {
            return;
        }

        this.timeoutId = setTimeout(() => {
            if (!terminalMenuStore.askForName || terminalMenuStore.isSkipClicked) {
                terminalMenuStore.showingPopup =
                    terminalMenuStore.POPUP_CONFIRM_ORDER;
            } else {
                terminalMenuStore.showLuckyWheelScreen = true;
            }
        }, duration);
    }

    handleGetReceiptClicked = () => {
        const { terminalMenuStore } = this.props;

        //If order placed via card
        if (terminalMenuStore.paymentType == "Card") {
            const email = terminalMenuStore.customerEmail;
            const orderNumber = terminalMenuStore.orderNumber;
            terminalMenuStore.sendStripeReceipt({
                email: email,
                order_id: orderNumber
            });
            if (email) {
                terminalMenuStore.receiptSent = true;
                this.setPopupTimeout(10000);
            } else {
                terminalMenuStore.showQR = true;
                if (this.timeoutId) {
                    clearTimeout(this.timeoutId);
                }
            }
        }
    };

    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }   

    renderQRCodeTxt = () => {
        const { terminalMenuStore } = this.props;
        let text = `https://wa.me/601154193392?text=Please%20send%20me%20the%20receipt%20for%20order%20${terminalMenuStore.orderNumber}`;
        let canvas = document.getElementById('qrcode');
        if (canvas) {
            QRCode.toCanvas(canvas, text, {
                width: 115,
                height: 111,
            });
        }
    };

    handleClosePopup = () => {
        const { terminalMenuStore } = this.props;
        if (!terminalMenuStore.askForName || terminalMenuStore.isSkipClicked) {
            terminalMenuStore.showingPopup =
                terminalMenuStore.POPUP_CONFIRM_ORDER;
        } else {
            terminalMenuStore.showLuckyWheelScreen = true;
        }
    }

    render() {
        const { terminalMenuStore } = this.props;
        if (terminalMenuStore.showQR) {
            setTimeout(() => {
                this.renderQRCodeTxt();
            }, 100);
        }
        return (
            <div className={base}>
                <div style={{ marginTop: '50px' }}>
                    <img src="/greenTick.png" />
                </div>

                {!terminalMenuStore.showQR && (
                    <div
                        style={{
                            fontSize: '64px',
                            fontWeight: 600,
                            maxWidth: '384px',
                            margin: '35px auto',
                        }}
                    >
                        {intl.get('terminal.qrPopup.payment_successful')}
                    </div>
                )}
                
                {
                    !terminalMenuStore.receiptSent && !terminalMenuStore.showQR && terminalMenuStore.paymentType == 'Card' && (
                        <div
                            style={{
                                margin: '15px auto 40px auto',
                                backgroundColor: '#FEC700',
                                width: '283px',
                                height: '50px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '5px'
                            }}
                            onClick={this.handleGetReceiptClicked}
                        >
                            <p style={{
                                fontSize: '24px',
                                fontWeight: '600',
                            }}>
                                    NEED A RECEIPT?
                            </p>
                        </div>
                    )
                }
                {
                    terminalMenuStore.receiptSent && (
                        <div
                            style={{
                                margin: '15px auto 40px auto',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <p style={{
                                fontSize: '24px',
                                fontWeight: '600',
                            }}>
                                    Receipt sent to your email address.
                            </p>
                        </div>
                    )
                }
                {
                    terminalMenuStore.showQR && (
                        <div
                            style={{
                                margin: '15px auto 40px auto',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <div style={{
                                fontSize: '36px',
                                fontWeight: '600',
                            }}>
                                    Scan this QR code to get your receipt
                            </div>
                            <canvas id="qrcode" style={{
                                    margin: '24px auto'
                            }}/>

                            <div
                                style={{
                                    margin: 'auto',
                                    backgroundColor: '#FEC700',
                                    width: '283px',
                                    height: '50px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '5px'
                                }}
                                onClick={this.handleClosePopup}
                            >
                                <p style={{
                                    fontSize: '24px',
                                    fontWeight: '600',
                                }}>
                                        Done
                                </p>
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}
